<script lang="ts" setup>
  import { normalizeStyle } from 'vue';

  interface IProps {
    radius?: string;
    type?: 'movie' | 'series' | 'continue' | 'channel';
    backgroundPlaceholder?: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    type: 'movie',
  });
</script>

<template>
  <div
    class="placeholder"
    :class="[`placeholder--${props.type}`, { 'placeholder--background': props.backgroundPlaceholder }]"
    :style="
      normalizeStyle({
        borderRadius: props.radius,
      })
    "
  ></div>
</template>

<style lang="scss">
  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: $main_white;
    height: 100%;

    &--movie {
      border-radius: 32px;
      aspect-ratio: 2 / 3;
    }
    &--series {
      border-radius: 16px;
    }
    &--continue {
      border-radius: 32px;
    }
    &--channel {
      border-radius: 24px;
    }
    &--background {
      border: none;
      background-image: url('~/assets/images/placeholder-genre.webp');
      background-size: cover;
      background-position: center center;
    }
    @media (max-width: $retina) {
      &--movie {
        border-radius: 16px;
      }
      &--continue {
        border-radius: 16px;
      }
      &--channel {
        border-radius: 12px;
      }
    }
  }
</style>
